/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 23, 2018 */

@font-face {
  font-family: "open-sans-condensed-bold";
  src: url("opensanscondensed-bold-webfont.woff2") format("woff2"),
    url("opensanscondensed-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open-sans-condensed-light";
  src: url("opensanscondensed-light-webfont.woff2") format("woff2"),
    url("opensanscondensed-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "open-sans-condensed-light-italic";
  src: url("opensanscondensed-lightitalic-webfont.woff2") format("woff2"),
    url("opensanscondensed-lightitalic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
